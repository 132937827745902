import React from 'react'
import styled from 'styled-components'
import { useContentful } from 'react-contentful'
import ReactMarkdown from 'react-markdown'

const StyledH3 = styled.h3`
  padding-bottom: 10px
`

export const Questions = (props) => {
  const { data, error, fetched, loading } = useContentful({
    contentType: 'questions',
    query: {
      order: 'fields.order',
    }
  });

  if (loading || !fetched) {
    return null;
  }

  if (error) {
    console.error(error);
    return null;
  }

  if (!data) {
    return <p>Page does not exist.</p>;
  }
  
  /* console.info(data) */

  return (
    <>
      <section className="container-default">
        <section className="container-questions">
          {data.items.map((item, index) => (
          <article className="question" key={index}>
            <StyledH3>{item.fields.question}</StyledH3>
            <ReactMarkdown>
              {item.fields.answer}
            </ReactMarkdown>
          </article>
          ))}
        </section>
      </section>
    </>
  )
}

export default Questions