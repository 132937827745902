import React from 'react'
import styled from 'styled-components'
import ImageGallery from 'react-image-gallery'
import { useContentful } from 'react-contentful'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'

import HeaderImage from '../../assets/img/darkmode-header.svg'
import FooterImage from '../../assets/img/darkmode-footer.svg'


const WrapperDarkMode = styled.div`
 margin: 0 auto;
  margin-top: 150px;
  margin-bottom: 150px;
  position: relative;
  display: block;
  width: 100%;
  max-width: 1240px;
  padding: 20px 30px;
  background-color: #603813;
  color: white;
  &::before {
    content: '';
    position: absolute;
    display: block;
    top: -108px;
    left: 0;
    width: 100%;
    height: 110px;
    background-color: transparent;
    background-image: url("${(props) => props.headerImage}");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}
&::after {
  content: '';
  position: absolute;
  display: block;
  bottom: -110px;
  left: 0;
  width: 100%;
  height: 110px;
  background-color: transparent;
  background-image: url("${(props) => props.footerImage}");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}`

const DarkH1 = styled.h1`
  background-image: -webkit-gradient(linear, left bottom, left top, from(#BA7943), to(#EDD7A6));
  background-image: linear-gradient(0deg, #BA7943, #EDD7A6);
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
  -webkit-box-decoration-break: clone;`

  const DarkH3 = styled.h3`
    color: #EDD7A6;
  `

const importAll = (r) => {
	let images = [];
  r.keys().forEach((item, index) => { 
    // images[item.replace('./', '')] = r(item); 
    images[index] = {'original': r(item), 'thumbnail': r(item)}; 
  });
	return images
}

export const About = () => {
  const required = require.context('../../assets/img/gallery', false, /\.(png|jpe?g|JPG|svg)$/)
  const images = importAll(required);

  const { data, error, fetched, loading } = useContentful({
    contentType: 'sections',
    query: {
      'fields.slug': `about`,
    }
  });

  if (loading || !fetched) {
    return null;
  }

  if (error) {
    console.error(error);
    return null;
  }

  if (!data.items[0]) {
    console.info("Section does not exist")
    return <p></p>;
  }
  
  // console.info(data)

  return (
    <WrapperDarkMode headerImage={HeaderImage} footerImage={FooterImage}>
      <section className="container-default align-center">
        <DarkH1>{data.items[0].fields.title1}</DarkH1>
        <DarkH3 className="darkmode">{data.items[0].fields.title2}</DarkH3>
      </section>
      <article className="container-default">
        {documentToReactComponents(data.items[0].fields.content)}
        <p>&nbsp;</p>
        <p>Bli med på en liten rundtur i vårt bildegalleri!</p>
      </article>
      <article className="placeholder-carousel">
        <ImageGallery items={images} showThumbnails={false} showPlayButton={true} showNav={true} showBullets={false} showIndex={true} />
      </article>
    </WrapperDarkMode>
  )
}
