import React, { Component } from 'react'
import styled from 'styled-components'
import PosterImage from '../../assets/img/olekjersti2.jpg'
 
const StyledPosterDiv = styled.div`
    margin: 0 auto;
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    top: 0;
    width: 100%;
    max-width: 1240px;
    min-height: 450px;
    height: 50vh;
    /* background-color: yellow; */
    background-image: url("${(props) => props.posterImage}");
    background-position: top center;
    background-size: cover;
    background-repeat: no-repeat;

    &:before {
        content: '';
        z-index: 1;
        position: absolute;
        display: block;
        top: 0;
        left: 0;
        width: 100%;
        min-height: 160px;
        height: 20vh;
        background: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 0.9)), to(rgba(0, 0, 0, 0)));
        background: linear-gradient(to bottom, rgba(0, 0, 0, 0.9) 0%, rgba(0, 0, 0, 0) 100%);
    }

    &:after {
        content: '';
        z-index: 1;
        position: absolute;
        display: block;
        bottom: 0;
        left: 0;
        width: 100%;
        min-height: 100px;
        height: 10vh;
        background: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 0)), to(rgba(237, 215, 166, 0.9)));
        background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(237, 215, 166, 0.9) 100%);
    }

    @media screen and (min-width: 768px) {
        background-position: center 30%;
    }
`; 
StyledPosterDiv.defaultProps = {
    posterImage: '',
}

export class Poster extends Component {
  render() {
    return (
        <StyledPosterDiv posterImage={PosterImage}>
            <div className="ribbon"></div>
        </StyledPosterDiv>
    )
  }
}
