import React from 'react'

export const Contact = () => {
  return (
    <>
        {/* <!-- Contact info --> */}
        <section className="container-default">
          <article className="align-center">
          <p>&nbsp;</p>
            <h1>Velkommen!</h1>
            <h3>Kontakt oss hvis du lurer på noe!</h3>
            <p>&nbsp;</p>
          </article>
          <article className="contactinfo">
            <div className="contact-person">
              <a href="mailto:kjerstilar@gmail.com">
                <div className="avatar avatar-kjersti"></div>
              </a>
              <h3>kjersti</h3>
              <h3><a href="mailto:kjerstilar@gmail.com">e-post</a></h3>
              <h3>924 85 873</h3>
            </div>
            <div className="contact-person">
              <a href="mailto:ole.hartvig@gmail.com">
                <div className="avatar avatar-ole"></div>
              </a>
              <h3>Ole</h3>
              <h3><a href="mailto:ole.hartvig@gmail.com">e-post</a></h3>
              <h3>932 85 060</h3>
            </div>
          </article>
        </section>
        {/* <!-- END Contact info --> */}
    </>
  )
}
