import React from 'react'
import styled from 'styled-components'
import { useContentful } from 'react-contentful'
// import ReactMarkdown from 'react-markdown'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'

const StyledContainerDefault = styled.section`
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-flow: column;
          flex-flow: column;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  /* position: relative;
	display: flex; */
  margin: 0 auto;
  width: calc(100% - 60px);
  max-width: 1190px;
  margin-bottom: 50px;

  & p {
    padding-bottom: 10px;
  }
`

export const Splash = (props) => {
  const { data, error, fetched, loading } = useContentful({
    contentType: 'sections',
    query: {
      'fields.slug': `splash`,
    }
  });

  if (loading || !fetched) {
    return null;
  }

  if (error) {
    console.error(error);
    return null;
  }

  if (!data) {
    return <p>Page does not exist.</p>;
  }
  
  // console.info(data)

  return (
    <>
      <section className="container-default align-center">
          <p>&nbsp;</p>
          <p>{data.items[0].fields.title1}</p>
          <h2>{data.items[0].fields.title2}</h2>
          <h1>{data.items[0].fields.title3}</h1>
          <h2>{data.items[0].fields.date}</h2>
      </section>
      <StyledContainerDefault>
        {documentToReactComponents(data.items[0].fields.content)}
      </StyledContainerDefault>
    </>
  )
}

export default Splash