import { ContentfulClient, ContentfulProvider } from 'react-contentful'
import { Poster } from './components/poster'
import { Splash } from './components/splash'
import { About } from './components/about'
import { Party } from './components/party'
import LocationMap from './components/map'
import { Contact } from './components/contact'
import { Questions } from './components/questions'

import './App.css';

const contentfulClient = new ContentfulClient({
  accessToken: 'KZOnqJzYlVUtePzGuLbjk1KC4rWI9jcJ9KcigzAky9A',
  space: 'rsmjswjf4c1k',
});

function App() {

  return (
    <ContentfulProvider client={contentfulClient} locale='nb-NO'>
      <div className="App">
        <Poster />
        <Splash />
        <About />
        <Party />
        <LocationMap />
        <Contact />
        <Questions />
        <footer></footer>
        <p>&nbsp;</p>
      </div>
    </ContentfulProvider>
  );
}

export default App;
